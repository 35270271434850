html, body {
  height: 100%;
  margin: 0;
}

.page-container {
  display: flex;
  margin-top: -100px;
  flex-direction: column;
  min-height: 100vh; /* Ensure it covers the full height of the viewport */
  background: url('../../../assets/meny-header.jpg') no-repeat center center fixed;
  background-size: cover;
}

.overlay {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  flex: 1; /* Ensure it takes up available space */
}

.header-container {
  margin-top: -100px;
  position: relative;
  width: 100%;
  height: 30vh; /* Adjust height as needed */
  overflow: hidden;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
}

.contacts-header-text {
  margin-top: 100px;

  color: #fff;
  font-size: 2em; /* Increased size for better visibility */
  font-weight: bold; /* Make text bold */
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 40vh; /* Align vertically */
  z-index: 2;
}

.contacts-container-wrapper {
  padding: 20px;
  margin: auto; /* Corrected typo from 'auo' to 'auto' */
  flex: 1; /* Ensure it takes up available space */
}

.contact-section {
  margin: 4rem 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact-container {
  flex: 1 1 30%;
  background-color: #1c77abb5;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.contact-container h2 {
  color: white;
  font-size: 1.5em;
  margin-bottom: 20px;
  font-weight: bold; /* Make headers bold */
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.contact-icon {
  font-size: 2em;
  color: #ffffff;
  margin-right: 10px;
}

.contact-page-info {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
}

@media (max-width: 950px) {
  .contact-section {
    flex-direction: column;
    align-items: center;
  }

  .contact-container {
    width: 85%;
  }

  .contact-icon {
    font-size: 1.5em;
  }

  .contact-page-info {
    font-size: 0.9em;
  }
}

/* Footer specific styling */
footer {
  margin-top: auto; /* Push footer to the bottom of the page */
}
