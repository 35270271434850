.gallery-container {
  height: 80vh;
  overflow: hidden;
  background-color:  #1c77ab;
  padding: 7vh 1rem;
  margin-bottom: 60vh;
  align-items: flex-start;
  display: flex;

}

.gallery-grid {
  display: grid;
  height: 80vh;
  gap: 1rem;
  overflow: hidden;
  grid-template-columns: repeat(2,1fr);

}

@media (min-width: 950px) {
  .gallery-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.gallery-column {
  display: grid;
  gap: 1rem;
}

.gallery-image {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}



@media screen and (max-width: 950px) {

.gallery-container{
  height: 90vh;
}

}






/*    Column Gallery  */

.column-gallery-container {
  height: 60vh;
  overflow: hidden;
  margin: 0% 0rem;
  border-radius: 0.5rem;
  width: 100%;
}


.column-gallery-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}


.column-gallery {
  display: grid;
  gap: 1rem;
}



.column-gallery-image {
  height: auto;
  max-width: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
}

@media screen and (max-width: 950px) {
  .column-gallery-container {
    height: 70vh;
    margin-bottom: 3rem;
    display: flex;
    align-items: start;
    align-content: start;
  }
}
