
.supplier-container{

  width: 100%;
  background-color: white;
}
.supplier-carousel {
    background-color: #fff;
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    padding:15vh 0;
  }
  

  
  .supplier-carousel h3 {
    font-size: medium;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .supplier-carousel .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .supplier-carousel img {
    max-width: 100%;
    max-height: 150px;
    margin: 0 auto; /* Ensure images are centered */
  }
  
  .supplier-carousel .slick-track {
    display: flex;
    align-items: center;
  }
  
  @media screen and (max-width: 950px) {
   
  
    .supplier-carousel img {
      max-height: 90px;
    }
  }
  

  .title{
 
      font-size: 26px;
      font-weight: 900;
      margin-bottom: 20px;
      color: #000;
  }
      
    
  