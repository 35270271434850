
/* Parallax Container */
.parallax {
    position: relative;
    overflow-x: hidden; /* Prevents horizontal overflow */
  }
  
  .parallax::before {
    content: "";
    background-image: url('../../../assets/heroSection/raw.jpg');
    position: fixed; /* Makes the background image fixed relative to the viewport */
    top: 0;
    right: 0;
    height: 100vh; /* Covers the full height of the viewport */
    width: 100vw; /* Covers the full width of the viewport */
    z-index: -1; /* Places the background behind other content */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; /* Ensures the background image covers the container */
  }
  
  /* Parallax Overlay Content */
  .parallax-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 1; /* Places overlay content above the parallax background */
  }
  
  .parallax-logo {
    width: 700px;
    height: auto;
    filter: invert(2);
  }
  
  /* Sections that scroll over the parallax background */
  .parallax-section {
    background: #fff;
    padding: 20px;
    margin: 200px 0;
    position: relative;
    z-index: 10; /* Ensures sections are above the background */
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 950px) {
    .parallax::before {
    width: 100vh;
    }
    .parallax-logo {
      width: 300px;
      height: auto;
    }
    
    .parallax::before {
      background-attachment: scroll; /* For mobile, scroll the background instead of fixed */
    }
  }
  