@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Miniver&display=swap');

/* Global styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;

}

.container-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  width: 90%;
  padding: 0 5%; /* Optional: Add padding for smaller screens */}



/* Specific styles for elements */
h1 {
    font-size: 60px;
    text-align: left;
}

p {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

/* Specific class for Miniver font */
.mini-ver {
    font-size: 25px;
    color: #1c77ab;
    text-align: left;
    font-family: 'Nerko One', cursive;
}

.btn {
  padding: 10px 35px;
  margin-top: 2rem;
  border: none;
  background-color: #1c77ab; /* Cor de fundo sólida */
  color: white; /* Cor do texto */
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
}

/* Estilo para o botão invertido */
.btn-inverted {
  padding: 10px 35px;
  margin-top: 2rem;

  border: 2px solid #1c77ab; /* Borda sólida */
  background-color: transparent; /* Fundo transparente */
  color: #8E1D22; /* Cor do texto */
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
}





@media screen and (max-width: 1000px) {

  .container-wrapper {
    margin: 0 auto;
    padding: 0 5%; /* Optional: Add padding for smaller screens */}
  
  
  
  /* Specific styles for elements */
  h1 {
      font-size: 5vw;
      text-align: left;
  }
  
  p {
      font-size: 20px;
      font-weight: 500;
      text-align: left;
  }
  
  /* Specific class for Miniver font */
  .mini-ver {
      font-size: 25px;
     
  }
  


}


















