.footer {
  background-color:  #1c77ab;
  color: #ffffff;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start; /* Alinha itens ao topo */
  flex-wrap: wrap;
  max-width: 1200px; /* Limita a largura máxima do conteúdo */
  margin: 0 auto; /* Centraliza o conteúdo horizontalmente */
  padding: 0 5%; /* Adiciona margem horizontal de 5% */
}

.footer-title {
  width: 100%;
  text-align: center; /* Centraliza o texto do título */
}

.footer-section {
  flex: 1 0 45%; /* Ocupa 45% da largura em telas maiores */
  margin: 10px;
  text-align: center; /* Centraliza o texto das seções */
}

.footer-section h2, .footer-section h3 {
  font-weight: 600;

  text-align: center; /* Centraliza o texto dos títulos */
}

.footer-section ul {
  font-weight: 500;

  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.footer-section ul li svg {
  margin-right: 10px;
}

.footer-section a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-rights {
  color: #fff;
  padding: 10px 0;
  text-align: center; /* Centraliza o texto do rodapé */
  font-size: 12px; /* Diminui o tamanho da fonte */
  margin-top: 20px;
  width: 90%;
  margin: 0 auto;
  
}


.social-icons {
  margin-bottom: 10px;
  text-align: center; /* Centraliza os ícones sociais */
}

.social-icons a {
  color: #ffffff;
  margin: 0 10px;
  text-decoration: none;
}

@media (max-width: 950px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    padding: 0 5%; /* Mantém a margem horizontal de 5% */
  }

  .footer-section {
    flex: 1 0 100%; /* Ocupa 100% da largura em telas menores */
    margin: 10px 0;
  }

  .footer-section ul li {
    justify-content: center; /* Centraliza o conteúdo das listas no mobile */
  }

  .social-icons {
    text-align: center; /* Centraliza os ícones sociais no mobile */
  }
}
