/* Main container for the two-column layout */
.two-column-container {
  display: flex;
  flex-direction: row; /* Keep columns side by side */
  align-items: flex-start;
  width: 100%;
  height: auto; /* Adjust based on content */
  margin: 0 auto; /* Center the container */
}

/* Left column styling */
.two-column-left-column {
  flex: 1;
  padding: 2rem 20px; /* Adjust padding as needed */
  box-sizing: border-box;
}

/* Right column styling */
.two-column-right-column {
  flex: 1;
  padding: 2rem 20px; /* Adjust padding as needed */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Text content inside the right column */
.right-content {
  max-width: 600px; /* Limit width for readability */
  margin: 0 auto;
}

.right-content p {
  text-align: justify;
}

/* Ensure responsive behavior */
@media screen and (max-width: 950px) {
  .two-column-container {
    flex-direction: column-reverse; /* Stack columns on small screens */
    height: auto; /* Adjust based on content */
    padding:  0; /* Adjust padding for smaller screens */
  }

  .two-column-left-column {
    width: 100%;
    padding: 10px 0; /* Adjust padding for mobile */
  }

  .two-column-right-column {
    width: 100%;
    padding: 0px 0; /* Adjust padding for mobile */
  }
}

/* Masonry grid styling for ColumnGallery component */
.masonry-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.masonry-item {
  margin-bottom: 10px;
}

.masonry-item img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
