


.hero-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Ajustado para ocupar a tela inteira */
  overflow: hidden;
  z-index: 2;
  background-image: url('../../../assets/heroSection/churrasqueria.jpg');
  background-size: cover;
  
  background-position: center; /* Centraliza a imagem de fundo */
  background-attachment: fixed; /* Fixa a imagem de fundo */
}

.hero-image {
  width: 100%;
  height: 100%; 
  object-fit: cover;
}

.hero-overlay {
  position: absolute; 
  top: 0;
  left: 0; 
  width: 100%; 
  height: 100%; 
  background-color: rgba(0, 0, 0, 0.5); 
  
  display: flex;
  justify-content: center;
  align-items: center; 
}

.hero-overlay img{
  width: 700px;
  height: auto;
  filter: invert(2);
}


@media screen and (max-width: 950px) {

  .hero-overlay img{
    width: 300px;
    height: auto;
    filter: invert(2);
  }
  

  .hero-container {
    position: relative;
    margin-top: -100px;
    width: 100%;
    height: 110vh; /* Ajustado para ocupar a tela inteira */
    overflow: hidden;
    background-image: url('');
    background-size: 100%;
    
    background-position: center; /* Centraliza a imagem de fundo */
    background-attachment: fixed; /* Fixa a imagem de fundo */
  }

}